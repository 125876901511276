import {
  Box,
  Button,
  Container,
  HStack,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useContext, type FunctionComponent } from "react";
import FlashAlerts from "~/components/FlashAlerts";
import PrimaryLogo from "~/components/Logo/PrimaryLogo";
import MessageModal from "./MessageModal";
import AppContext from "~/contexts/AppContext";

const Header: FunctionComponent = observer(function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const appStore = useContext(AppContext);

  const actionButton =
    appStore.authenticated && appStore.loaded ? (
      <a href={appStore.trueUser?.homePage}>
        <Button colorScheme="gray">Dashboard</Button>
      </a>
    ) : (
      <a href="/users/sign_in">
        <Button colorScheme="gray">Sign In</Button>
      </a>
    );

  return (
    <Box w="100vw" backgroundColor="white">
      <Container maxW="container.xl">
        <FlashAlerts />
        <HStack w="100%" h="72px">
          <a href="/">
            <PrimaryLogo />
          </a>
          <Spacer />
          {actionButton}
          <Button colorScheme="blue" onClick={onOpen}>
            Learn More
          </Button>
        </HStack>

        <MessageModal isOpen={isOpen} onClose={onClose} />
      </Container>
    </Box>
  );
});

export default Header;
