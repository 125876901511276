import { Container, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { type FunctionComponent } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Markdown from "react-markdown";
import "github-markdown-css/github-markdown-light.css";
import "./index.css";

const PrivatePolicyMarkdown = `
**Privacy Policy**

This Privacy Policy (“**Privacy Policy**” or “**Policy**”) governs all users of the website of Cosign, Inc. (referred to as “**Company**,” “**Cosign**,” “**We**,” or “**Us**”) accessible at [www.rentwithcosign.com](http://www.rentwithcosign.com) (referred to as the “**Cosign Site**” or “**this Site**”). This Policy is provided to explain our practices regarding the privacy of information we may collect during your use of the Cosign Site.

BY CONTINUING TO USE THIS SITE, YOU AGREE TO BE BOUND BY ALL TERMS AND CONDITIONS OF THIS POLICY AS PRESENTED TO YOU ON THE DATE OF SUCH USE (THE “**EFFECTIVE DATE**”). NO CHANGES (ADDITIONS OR DELETIONS) TO THIS POLICY WILL BE ACCEPTED BY THE COMPANY. IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS OF THIS POLICY, YOU SHOULD NOT USE THIS SITE. YOU SHOULD DOWNLOAD AND PRINT THIS POLICY FOR YOUR RECORDS.

**Collection of Personally Identifiable Information** During your use of the Site, we may collect and retain the following (referred to as your “personally identifiable information”): (i) information such as your name, email address, mailing address, phone number, and other personal data; (ii) any content or other data that you have uploaded, distributed, published, or otherwise used via the Site; or (iii) any other information or data about how you use, or the results of your use of, the Site.

Your personally identifiable information collected during your use of the Site may be used for the following purposes: (i) to provide you with proper access to and use of the Site; (ii) to send you notices, material, or other information about features or functionalities of the Site; (iii) to send you notices, material, or other information about any services, products, goods, or other items from the Company or any commercial partners of the Company (“**Our Commercial Partners**”) (collectively “**Products**”); (iv) to provide you with any other information about the Company, the Company’s Products, or Products of Our Commercial Partners; (v) to inform you about any events, functions, or items promoted or made available by the Company or any of Our Commercial Partners; (vi) for any other lawful purpose; or (vii) to gather, analyze, or use any information collected through any affiliates of Cosign, for making an assessment for the approval or denial of any User using the Cosign Site (referred to as the “**Intended Uses**”).

The Company does not intentionally share personally identifiable information with third parties without your consent. However, we may share your personal identifiable information in the following circumstances: (i) with a third party (including, without limitation, Our Commercial Partners) to perform any features or functions you request via the Site; (ii) to comply with applicable laws or enforce our rights; (iii) with any business partners or affiliates of the Company, including Our Commercial Partners, for any of the “**Intended Uses**” noted in this Policy; and/or (iv) to the extent, and pursuant to, the terms of the Site’s “**Terms of Use**” agreement. If you have any hesitation about providing such information to us and/or having such information used as permitted in this Policy or the “**Terms of Use**”, you should discontinue using the Site.

If you have provided us with personally identifiable information and subsequently do not wish to receive further email communications from the Company, you must send an email to <support@rentwithcosign.com> stating that you no longer wish to receive email communications from the Company (“**Opt Out**”). Once the Company receives your Opt Out request, we will use commercially reasonable efforts to honor your request within a commercially reasonable time. However, we cannot retrieve or prevent the further use of your personal identifiable information that may have been shared with others up to that time.

If you opt out and properly deliver your Opt Out request to the Company in accordance with this Privacy Policy, you will no longer receive emails directly from the Company after a commercially reasonable time from the date you send your Opt Out notice. However, the Company is not responsible for communications from other entities (including Our Commercial Partners) or for your use of other internet websites, blogs, bulletin boards, chat rooms, and similar forums not under the Company’s control and ownership.

**Enforcement of This Privacy Policy** By using this Site, including uploading any of your personal identifiable information or any other content via this Site, you: (a) unconditionally agree to all the terms and conditions of this Privacy Policy; and (b) agree that no provision of this Privacy Policy, including the Opt Out provisions, shall limit or amend any rights you have granted to the Company pursuant to the Site’s “**Terms of Use**” agreement. In case of inconsistency between the Terms of Use and this Privacy Policy, the Terms of Use shall govern.

**Protection of Personally Identifiable Information** Except for use by internet search engines, Our Commercial Partners, and as set forth in this Privacy Policy and the Site’s “**Terms and Conditions**”, we generally do not make available to the general public any other personally identifiable information you provide in connection with your use of the Site. The transmission of personally identifiable information between you and the Site may not be encrypted or secure. While we strive to protect your personal identifiable information, we cannot guarantee the security of any information transmitted to, from, or over the Site, and you do so at your own risk.

No data transmissions over the Internet can be guaranteed to be 100% secure. Therefore, we cannot ensure or warrant the security of any information you transmit to the Company and you do so at your own risk. Once the Company receives your transmission, we will take commercially reasonable efforts to protect your information from unauthorized access, disclosure, alteration, or destruction. However, you understand and agree that any such efforts are not a guarantee that such information may not be accessed, disclosed, altered, or destroyed by a breach of such firewalls and secure server software.

If the Company learns of a security systems breach, we may use commercially reasonable efforts to notify you electronically or by other means so that you can take protective steps. By using the Cosign Site or providing any personal information to the Company, you agree that the Company has the right to communicate with you electronically or by other means regarding security, privacy, and administrative issues related to your use of the Cosign Site. The Company may post a notice on the Cosign Site if a security breach occurs. You will need a web browser to access and view the notice on the Cosign Site. The Company may also send an email to the email address you provided. Depending on where you live and the circumstances of a security breach, you may have a legal right to receive written notice of a security breach. To receive a free written notice of a security breach or to withdraw your consent from receiving electronic notice, you should notify us via email at <support@rentwithcosign.com>.

**Accessing and Updating Your Personally Identifiable Information** You are solely responsible for correcting, updating, modifying, or eliminating your personally identifiable information as it appears in, and as otherwise stored or contained in, the Site. We have no duty to correct, update, modify, or eliminate any of your personally identifiable information as it appears in, is stored, or is otherwise contained in the Site.

**Links to and Use of Third-Party Sites** The Site may, now or in the future, provide links or other access to internet websites, blogs, bulletin boards, chat rooms, and similar forums not under the Company’s control and not solely owned by the Company (collectively referred to as “**Third-Party Sites**”). If you click on a link to or gain access to such a Third-Party Site, you will be transported to one of these Third-Party Sites. THIS PRIVACY POLICY DOES NOT DESCRIBE THE PRIVACY POLICIES OF THIRD-PARTY SITES REGARDING THEIR COLLECTION AND USE OF PERSONALLY IDENTIFIABLE INFORMATION. THE COMPANY IS NOT RESPONSIBLE FOR THE PRIVACY PRACTICES OF THIRD-PARTY SITES. IF YOU CLICK ON A LINK TO, OR ARE SENT TO, OR GAIN ACCESS TO, A THIRD-PARTY SITE, YOU SHOULD REVIEW THE PRIVACY STATEMENTS OR POLICIES OF THAT THIRD-PARTY SITE TO DETERMINE ITS PRACTICES REGARDING THE COLLECTION AND USE OF PERSONALLY IDENTIFIABLE INFORMATION. If you use any Third-Party Site in any way, you acknowledge that any of your personally identifiable information you provide might be read, collected, or otherwise used by other users of that Third-Party Site or other third parties, and could be used to send you unsolicited messages. We are not responsible for any personally identifiable information you choose to submit to or make available to these Third-Party Sites. Any link on the Site to a Third-Party Site does not imply an endorsement of the privacy practices of such Third-Party Site by Us, and no Third-Party Site is authorized to make any representation or warranty on our behalf.

**Non-Personally Identifiable Information** During your visit to the Site, we may use your Internet Protocol (“**IP**”) address to help administer the Site. Our server may place small temporary text files called “cookies” on your computer's processor or in the browser directory of your hard drive. We use your IP address and/or cookies to gather non-personally identifiable demographic information, such as how many users visited a certain part of the Site, but not to track a specific IP address to the identity of a particular individual user. You may choose not to accept cookies by setting your web browser to either refuse all cookies or notify you each time before a cookie is set.

**Do Not Track** Some browsers have a “**do not track**” feature that lets website users inform websites or other applications that they do not want to have their online activities tracked. These “**do not track**” features may also give website users other choices regarding the collection of their personally identifiable information. However, these “**do not track**” features and signals are not yet uniform. Accordingly, users of this Site are notified that this Site is not currently set up to respond to any “do not track” features or signals.

**This Privacy Policy May Change** This Privacy Policy may be changed by the Company at any time and without notice to you. YOU ARE BOUND BY THE VERSION OF THIS PRIVACY POLICY THAT IS IN EFFECT AT THE TIME YOU VISIT THE SITE. THE TERMS OF THIS PRIVACY POLICY MAY BE DIFFERENT THE NEXT TIME YOU USE THIS SITE. ANY USE OF THE COMPANY’S SITE BY YOU AFTER SUCH AMENDMENT(S) SHALL BE DEEMED TO CONSTITUTE ACCEPTANCE BY YOU OF THE AMENDMENT(S). YOU SHOULD REGULARLY REVIEW AND PRINT THIS PRIVACY POLICY FOR YOUR RECORDS.

If you have any questions about this Privacy Policy or believe that we have not complied with its terms, please contact us at <support@rentwithcosign.com>.
`;

const PrivatePolicyPage: FunctionComponent = observer(
  function PrivatePolicyPage() {
    return (
      <>
        <Header />
        <Container maxW="container.xl">
          <VStack justifyContent="cetner" paddingX="10px">
            <Container maxW="container.lg">
              <div className="markdown-body notranslate">
                <Markdown>{PrivatePolicyMarkdown}</Markdown>
              </div>
            </Container>
          </VStack>
        </Container>
        <Footer />
      </>
    );
  }
);

export default PrivatePolicyPage;
