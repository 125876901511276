import {
  Box,
  Container,
  VStack,
  Stack,
  Text,
  Divider,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useContext, type FunctionComponent } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import {
  BsEnvelope,
  BsTelephone,
  BsMailbox,
  BsFacebook,
  BsTwitter,
  BsInstagram,
} from "react-icons/bs";
import PrimaryLogo from "~/components/Logo/PrimaryLogo";
import FlashContext from "~/contexts/FlashContext";
import PublicAxios from "~/services/PublicAxios";

interface FooterProps {
  backgroundColor?: string;
}

interface FooterFormState {
  name: string;
  email: string;
  message: string;
}

const FooterForm: FunctionComponent = observer(function FooterForm() {
  const flashStore = useContext(FlashContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FooterFormState>({});

  const onSubmit: SubmitHandler<FooterFormState> = (data) => {
    PublicAxios.post("/api/public/contact_forms", {
      contactForm: {
        name: data.name,
        email: data.email,
        message: data.message,
      },
    })
      .then(() => {
        flashStore.addFlashToCurr({
          type: "success",
          message:
            "Thanks for contacting us, a member of the team will reach out.",
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch(() => {
        flashStore.addFlashToCurr({
          type: "error",
          message: "Something went wrong, please try again.",
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
  };

  return (
    <Box
      width={{ base: "90%", lg: "500px" }}
      padding="32px"
      borderRadius="16px"
      borderWidth="1px"
      borderColor="gray.300"
      backgroundColor="#F7F7F8"
    >
      <form onSubmit={handleSubmit(onSubmit) as () => void}>
        <FormControl isInvalid={errors.name !== undefined} pb="24px">
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            placeholder="Your name"
            {...register("name", {
              required: "name is required",
              maxLength: {
                value: 100,
                message: "Maximum length of name is 100 characters",
              },
            })}
            backgroundColor="white"
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.email !== undefined} pb="24px">
          <FormLabel>Email</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={BsEnvelope} boxSize={5} />
            </InputLeftElement>
            <Input
              type="email"
              placeholder="username@example.com"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Email must be in username@domain.com format",
                },
              })}
              backgroundColor="white"
            />
          </InputGroup>
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.message !== undefined} pb="24px">
          <FormLabel>Message</FormLabel>
          <Textarea
            placeholder="Write a message..."
            {...register("message", {
              required: "message is required",
              maxLength: {
                value: 1000,
                message: "Maximum length of the message is 1000 characters",
              },
            })}
            minH="150px"
            backgroundColor="white"
          />
          <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
        </FormControl>

        <Button
          mt={4}
          width="100%"
          color="white"
          backgroundColor="#0283C5"
          isLoading={isSubmitting}
          type="submit"
        >
          Send Message
        </Button>
      </form>
    </Box>
  );
});

const Footer: FunctionComponent<FooterProps> = observer(function Footer({
  backgroundColor,
}) {
  backgroundColor ??= "white";

  return (
    <Box w="100vw" backgroundColor={backgroundColor}>
      <Container maxW="container.xl">
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing="60px"
          verticalAlign="center"
          justify="center"
          align="center"
          marginTop="60px"
          marginBottom="100px"
          id="contact"
        >
          <Box maxW="500px">
            <Text fontSize="36px" fontWeight="extrabold" color="black">
              Contact
            </Text>
            <Text
              fontSize="18px"
              fontWeight="normal"
              color="black"
              paddingTop="20px"
            >
              Contact us for inquiries, support, or just to say hello. We&apos;d
              love to hear from you!
            </Text>

            <HStack paddingTop="40px">
              <Icon as={BsTelephone} boxSize={6} color="#0283C5" />
              <Text fontSize="18px" fontWeight="bold" color="black">
                914-200-4771
              </Text>
            </HStack>

            <HStack paddingTop="20px">
              <Icon as={BsMailbox} boxSize={6} color="#0283C5" />
              <Text fontSize="18px" fontWeight="bold" color="black">
                info@rentwithcosign.com
              </Text>
            </HStack>

            <HStack paddingTop="40px">
              <Icon as={BsFacebook} boxSize={6} color="gray.300" />
              <Icon
                as={BsTwitter}
                boxSize={6}
                color="gray.300"
                marginLeft="15px"
              />
              <Icon
                as={BsInstagram}
                boxSize={6}
                color="gray.300"
                marginLeft="15px"
              />
            </HStack>
          </Box>

          <FooterForm />
        </Stack>

        <VStack marginBottom="40px">
          <a href="/">
            <PrimaryLogo />
          </a>

          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={{ base: "20px", md: "40px" }}
            verticalAlign="center"
            justify="center"
            align="center"
            marginTop="20px"
          >
            <a href="/">
              <Text fontSize="16px" fontWeight="normal" color="gray.700">
                Home
              </Text>
            </a>
            <a href="/faqs">
              <Text fontSize="16px" fontWeight="normal" color="gray.700">
                FAQ
              </Text>
            </a>
            <a href="#contact">Contact</a>;
            <a href="/terms_of_service">
              <Text fontSize="16px" fontWeight="normal" color="gray.700">
                Terms of Service
              </Text>
            </a>
            <a href="/private_policy">
              <Text fontSize="16px" fontWeight="normal" color="gray.700">
                Private Policy
              </Text>
            </a>
          </Stack>

          <Divider marginY="20px" />

          <Text fontSize="16px" fontWeight="normal" color="gray.700">
            &copy; {new Date().getFullYear()} Cosign. All rights reserved.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
});

export default Footer;
