import React, { type FunctionComponent } from "react";
import { observer } from "mobx-react-lite";
import Application from "~/components/Application";
import type FlashStore from "~/stores/FlashStore";
import Routes from "./Routes";

interface HomeAppProps {
  authenticated: boolean;
  flashStore: FlashStore;
}

const gTranslateConfiguration = {
  default_language: "en",
  native_language_names: true,
  detect_browser_language: true,
  languages: ["en", "es"],
  wrapper_selector: ".gtranslate_wrapper",
  switcher_horizontal_position: "left",
  switcher_vertical_position: "bottom",
  float_switcher_open_direction: "bottom",
  flag_size: 24,
  alt_flags: { en: "usa" },
};

const HomeApp: FunctionComponent<HomeAppProps> = observer(function HomeApp({
  authenticated,
  flashStore,
}: HomeAppProps) {
  return (
    <Application
      authenticated={authenticated}
      gTranslateConfiguration={gTranslateConfiguration}
      flashStore={flashStore}
    >
      <Routes />
    </Application>
  );
});

export default HomeApp;
