import { Container, Tab, TabList, Tabs, Text, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { type FunctionComponent } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const FaqIndexPage: FunctionComponent = observer(function FaqIndexPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onTabChange = (index: number): void => {
    const path = index === 0 ? "/faqs/renters" : "/faqs/property_managers";
    navigate(path);
  };

  const tabIndex = pathname === "/faqs/property_managers" ? 1 : 0;

  return (
    <>
      <Header />
      <Container maxW="container.lg">
        <VStack justifyContent="cetner" paddingX="10px" marginTop="40px">
          <Text fontSize="36px" fontWeight="extrabold" textAlign="center">
            Frequently Asked Questions
          </Text>
          <Tabs
            isFitted
            w="100%"
            index={tabIndex}
            onChange={onTabChange}
            marginTop="40px"
          >
            <TabList>
              <Tab>Renters</Tab>
              <Tab>Property Managers</Tab>
            </TabList>
          </Tabs>

          <Container maxW="container.lg" marginY="30px">
            <Outlet />
          </Container>
        </VStack>
      </Container>
      <Footer />
    </>
  );
});

export default FaqIndexPage;
