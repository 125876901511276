import React, { useContext, useEffect, type FunctionComponent } from "react";
import { Route, Routes as ReactRouterRoutes } from "react-router-dom";
import IndexPage from "./pages/IndexPage";
import { observer } from "mobx-react-lite";
import FaqIndexPage from "./pages/Faqs/IndexPage";
import PropertyManagersFaqPage from "./pages/Faqs/PropertyManagersPage";
import RentersFaqPage from "./pages/Faqs/RentersPage";
import PrivatePolicyPage from "./pages/PrivatePolicy/IndexPage";
import TermsOfServicePage from "./pages/TermsOfService/IndexPage";
import ExclusionsPage from "./pages/Exclusions/IndexPage";
import type AppStore from "~/stores/AppStore";
import AppContext from "~/contexts/AppContext";

const Routes: FunctionComponent = observer(function Routes() {
  const appStore: AppStore = useContext(AppContext);

  useEffect(() => {
    if (!appStore.loaded) return;

    appStore.enableGTranslate();
  }, [appStore.loaded]);

  return (
    <>
      <ReactRouterRoutes>
        <Route index element={<IndexPage />} />
        <Route path="faqs" element={<FaqIndexPage />}>
          <Route index element={<RentersFaqPage />} />
          <Route
            path="property_managers"
            element={<PropertyManagersFaqPage />}
          />
          <Route path="renters" element={<RentersFaqPage />} />
        </Route>
        <Route path="private_policy" element={<PrivatePolicyPage />} />
        <Route path="terms_of_service" element={<TermsOfServicePage />} />
        <Route path="exclusions" element={<ExclusionsPage />} />
      </ReactRouterRoutes>
      <div className="gtranslate_wrapper"></div>
    </>
  );
});

export default Routes;
