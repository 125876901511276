import { ListItem, UnorderedList } from "@chakra-ui/react";
import React, { type ReactNode } from "react";

interface Faq {
  question: string;
  answer: ReactNode;
}

export const RenterFaqs: Faq[] = [
  {
    question: "What is Cosign?",
    answer:
      "Similar to a parent cosigner, Cosign acts as a safeguard legally responsible for fulfilling rent obligations in case of default. It validates your lease in lieu of a traditional guarantor.",
  },
  {
    question: "Why consider using Cosign and who uses it?",
    answer:
      "Landlords establish stringent standards for qualification, posing challenges for a majority of Americans.  Cosign aids prospective residents unable to meet income or credit prerequisites. The most common applicants include non-US citizens with no established American credit, students lacking income,  professionals with limited credit profiles, and self-employed individuals.",
  },
  {
    question: "Does Cosign pay my rent?",
    answer:
      "No. All renters are required to fulfill all financial obligations under the lease agreement, including rent payments. Cosign serves solely to guarantee your lease to the landlord and assist in qualifying for your apartment.",
  },
  {
    question: "Are all Property Management Companies accepting Cosign?",
    answer:
      "Not every property management company YET - but many do, and new properties are joining every day. In cases where a community does not work with Cosign, please let us know and we will reach out to your landlord and aim to get them signed up quickly. Alternatively, we can assist in locating a building already enrolled in the program.",
  },
  {
    question: "What are the Benefits of using Cosign?",
    answer: (
      <UnorderedList>
        <ListItem>Expands housing options</ListItem>
        <ListItem>
          If you apply for housing and require a co-signer you now have another
          option in Cosign
        </ListItem>
      </UnorderedList>
    ),
  },
  {
    question: "How do I qualify for Cosign?",
    answer:
      "To qualify for Cosign, your application undergoes a credit check with a soft pull on your credit report. We evaluate your credit against our proprietary screening criteria, facilitating a speedy approval process that typically takes less than an hour.",
  },
  {
    question: "What is the cost of Cosign?",
    answer:
      "Cosign fees typically range from 40% to 115% of one month's rent. This is a one-time fee per lease, not per renter.",
  },
  {
    question: "What's the approval timeline with Cosign?",
    answer:
      "Once you submit an application, you will hear back from us regarding the next steps within 30 minutes during normal business hours. Generally, most of our applicants are ready to move in the same day!",
  },
  {
    question: "Do all roommates need to qualify for Cosign?",
    answer: "No, only the Primary Applicant is required to qualify.",
  },
  {
    question: "What's the coverage period for Cosign?",
    answer:
      "Cosign covers up to a 24 month lease term however the standard coverage is based off a 12-month lease.",
  },
  {
    question: "How does the complete application process work with Cosign?",
    answer: (
      <UnorderedList>
        <ListItem>
          Applications are submitted by all tenants on our website. Once
          everyone&apos;s application is complete, you&apos;ll get a decision
          within 30 minutes.
        </ListItem>
        <ListItem>
          Once approved, everyone on the lease signs our agreement and pays the
          fee.
        </ListItem>
        <ListItem>
          We provide all the necessary documents to your landlord. You&apos;re
          ready to sign your lease and move in!
        </ListItem>
      </UnorderedList>
    ),
  },
  {
    question: "Can Cosign assist non-US citizens?",
    answer:
      "Yes! Cosign welcomes applications from foreign citizens authorized to study, work, or reside in the US.",
  },
  {
    question: "When does Cosign become effective?",
    answer:
      "Cosign becomes effective once the Approval Agreement is signed and the fee is paid.",
  },
  {
    question: "What are my lease obligations with Cosign?",
    answer: (
      <UnorderedList>
        <ListItem>
          You must adhere to all Terms and Conditions of the Lease
        </ListItem>
        <ListItem>
          While the property management company is covered if you stop paying
          rent, you remain responsible for the owed debt
        </ListItem>
        <ListItem>
          Any outstanding debt will be forwarded to collections
        </ListItem>
      </UnorderedList>
    ),
  },
  {
    question: "What documents are required for applying with Cosign?",
    answer:
      "Depending on your situation, various documents might be necessary. Typically, we request photo ID, pay stubs, recent bank statements, and, if applicable, copies of your student or work Visa. Additional or alternative documents may be required for students, 1099 workers, internationals, and retirees.",
  },
  {
    question: "Why do we need these documents for Cosign?",
    answer:
      "Cosign assumes a substantial risk by co-signing your lease. To manage this risk, we conduct a thorough review process, necessitating these documents to determine eligibility.",
  },
  {
    question: "How can students qualify with Cosign?",
    answer:
      "Full-time enrollment at an accredited institution (usually 12 credit hours per term) for the entire lease term is required. A cumulative GPA of 2.0 or higher is also necessary.",
  },
  {
    question: "What are the payment methods for Cosign's fee?",
    answer:
      "Cosign's fee can be settled via credit/debit card. Payment is collected before lease signing.",
  },
  {
    question: "Is the payment for Cosign a security deposit?",
    answer:
      "No. Cosign's fee is a one-time payment to secure the apartment, separate from any property-required security deposit.",
  },
  {
    question: "What if the chosen apartment doesn't partner with Cosign?",
    answer:
      "Provide us with the building or landlord details, and we will connect with them regarding getting onboarded with Cosign. Most landlords can be registered as partners on the same business day.  Alternatively, we can assist in locating a building already enrolled in the program.",
  },
  {
    question: "What are the repercussions of not paying rent with Cosign?",
    answer:
      "Our guarantee does not exempt you from obligations in your lease agreement. Defaulting on your lease could lead to legal action, eviction, and adversely impact your credit. You remain liable for any missed payments outlined in your lease agreement.",
  },
];

export const PmFaqs = [
  {
    question: "What is Cosign?",
    answer:
      "Cosign is  co-signing product and service for renters needing a qualified co-signer to facilitate housing access. It assists renters who may not meet conventional requirements, benefiting property owners and operators by enhancing lease conversion rates and minimizing default risks, thereby improving their Net Operating Income (NOI). In the event of renter default on the lease, Cosign reimburses owners and operators for missed rent payments, enabling confident leasing for operators and granting renters access to desired apartments.",
  },
  {
    question: "How much does it cost?",
    answer:
      "There is no cost to Property Managers (PMs). The  fee for Cosign is paid by the resident. ",
  },
  {
    question: "Who typically benefits from Cosign?",
    answer:
      "Cosign assists prospective residents falling short of income or credit prerequisites. Common applicants include non-US citizens without established American credit, income-lacking students, young professionals with limited credit history, and self-employed individuals.",
  },
  {
    question: "What does a Cosign guarantee cover?",
    answer:
      "The Cosign guarantee covers up to three months of lost rent due to renter defaults, skips, or is evicted. Coverage extends past move-out date until the property is re-rented up to the coverage limit. We will even cover a portion of your legal costs and damages!",
  },
  {
    question: "What are the benefits of Cosign compared to a cosigner?",
    answer:
      "Chasing down distant parent or relative co-signers is never ideal!  Cosign reduces vacancy losses, broadens the rental pool, guarantees rental losses, thus boosting NOI, and increases property traffic.",
  },
  {
    question: "How do I sign up to start accepting Cosign?",
    answer:
      "Complete the landlord contact form on our website, and our team will reach out!",
  },
  {
    question: "Does the coverage continue after lease renewal?",
    answer:
      "No, once the lease ends, so does the guarantee. If the resident still doesn’t meet standard screening criteria without the guarantee, they'll need to reapply for the next lease term.",
  },
  {
    question: "How long does it take to process an application with Cosign?",
    answer:
      "Completed applications are reviewed within 30 minutes. Upon receiving and reviewing all documents, residents can sign their lease within an hour.",
  },
  {
    question: "Will accepting Cosign alter my leasing process?",
    answer: "No, we adapt to fit within your standard procedures.",
  },
  {
    question: "What's the Security Deposit policy with Cosign?",
    answer: "Your standard auto-approve deposit policy applies.",
  },
  {
    question: "How long is the Cosign coverage valid?",
    answer:
      "The Cosign coverage remains valid for the lease term, with a standard lease term of 12 months and extended coverage options available up to a maximum of 24 months.",
  },
  {
    question: "Does the renter still have to comply with the Lease terms?",
    answer: "Yes, the renter is bound by all Lease agreement terms.",
  },
  {
    question: "What happens if a covered resident defaults on rent?",
    answer:
      "You operate under normal course. Initiate eviction proceedings and file a claim with Cosign within 120 days of the initial default date. Submit all required documents at claim submission. Once possession is regained the claim payment will be issued with subsequent payments being made monthly upon receiving an updated ledger and re-let status confirmation.",
  },
  {
    question: "How long do I have to prepare a vacant property for lease?",
    answer:
      "The vacant unit must be turned and leasable within 10 business days of the vacate date. Exceptions may apply.",
  },
  {
    question: "How can I be assured of payment for a Renter default?",
    answer:
      "Cosign maintains an escrow account covering all default claims. The process is simple, on the portal click on initiate a default claim to recover lost rent, damages and legal costs up to 3x the rental rate.",
  },
  {
    question: "How fast will I receive payment in case of default?",
    answer: "All claims are typically paid within 5 business days.",
  },
  {
    question: "Does Cosign prevent eviction of a resident?",
    answer:
      "No, the lease still governs the resident relationship. Cosign serves to protect and limit your risk. Follow standard eviction procedures in case of non-payment, and once possession is regained, receive payment for missed rent up to the coverage amount chosen for the property or until re-letting, whichever occurs first.",
  },
  {
    question: "What is the owner and operator portal?",
    answer:
      "The portal is for owners and operates to facilitate inviting residents to apply, tracking progress and monitoring property and portfolio trends.",
  },
];
