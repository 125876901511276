import { Container, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { type FunctionComponent } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "github-markdown-css/github-markdown-light.css";
import "./index.css";

const ExclusionsHtml = (
  <>
    <p>
      <strong>Exclusions</strong>
    </p>

    <ol type="A">
      <li>
        Cosign, Inc.&#39;s (&quot;Cosign&quot;) liability for any or all Cosign
        Payment(s) shall be the lesser of (a) the actual lost rent and actual
        reasonable out-of-pocket repair costs for property damage and legal fees
        sustained, or (b) the Maximum Payment.
      </li>
      <li>
        Cosign shall have no liability under this agreement, or otherwise,
        except as expressly set forth herein. The Applicant shall not, and shall
        have no obligation to, provide a defense to or defend the Landlord or
        Tenant against any claims made, or suits brought, whether such claims or
        suits are brought by a Tenant (or Tenants), Landlord or any third party
      </li>
      <li>
        Cosign will not be liable under any circumstances, and Cosign Payment is
        not included and is specifically excluded hereunder, for any payments
        caused by, resulting from or related to any of the:
        <ol type="1">
          <li>
            The Tenant&#39;s inability to occupy the Unit because of:
            <ol type="a">
              <li>constructive eviction or due to ordinance and law;</li>
              <li>
                any breach of the implied warranty of habitability by the
                Landlord or Unit owner;
              </li>
              <li>
                Physical Damage to the Unit, the surrounding areas, or access
                thereto;
              </li>
              <li>
                damage caused by weather, including but not limited to
                hurricanes, tornadoes, typhoons, earthquakes, or any other act
                of God;
              </li>
              <li>
                ﬂooding or water intrusion that materially and negatively
                affects the safety, ingress/egress, or ability to occupy the
                Unit;
              </li>
              <li>
                damage caused by smoke or ﬁre, including damage from water to
                extinguish a ﬁre;
              </li>
              <li>
                the discovery of materials in the air or on surfaces that are
                reasonably considered to be hazardous to the health or
                well-being of the Tenant or any other occupant of the Unit;
              </li>
              <li>
                the infestation of pests, vermin, or insects of the Unit or the
                areas immediately around the Unit; or
              </li>
              <li>
                discrimination prohibited by applicable law against a Tenant by
                the Landlord, Leasing Agent, or any other agent of the Landlord.
              </li>
            </ol>
          </li>
          <li>
            Damage to or Loss of personal property of the Tenant or vandalism to
            the Unit not caused by Tenant;
          </li>
          <li>
            Any existing or future property insurance policy or any other
            insurance policy in effect between the Landlord and parties not
            explicitly included in this Bond;
          </li>
          <li>
            Any Loss, the claim for which contains any material misstatement,
            material misrepresentation or material omission, or involves any act
            of fraud by the Obligee, its partners, authorized representatives,
            employees, temporary employees or leased employees;
          </li>
          <li>Third party claims or damage;</li>
          <li>Damages resulting from Normal Wear and Tear;</li>
          <li>
            Any expenses, Damages, fees, costs or other amounts incurred by the
            Landlord which are not legally or otherwise eligible to be withheld
            from a Security Deposit;
          </li>
          <li>
            Out-of-Pocket Expenses incurred by the Landlord including but not
            limited to ﬁnes, attorney&#39;s fees, regulatory fees,
            administrative fees, late fees, management fees or any other fees
            not included in the Rent except as set forth in Article 7 (In the
            Event of a Loss section of the Bond Agreement);
          </li>
          <li>
            Early termination of a lease wherein the Landlord and Tenant agree
            to relinquish the contractual obligation of Rent for the remainder
            of the lease tenancy.
          </li>
          <li>Terrorism;</li>
          <li>
            Failure of the Tenant to make the initial Rent payment to the
            Landlord under a Lease. In the event the ﬁrst month of Rent is paid
            for or waived by the Unit owner or Landlord, the initial Rent
            payment shall mean the Rent payment due the month following such
            waiver or payment by the Unit owner or Landlord;
          </li>
          <li>
            Extension, renewal, or change to the terms of the Lease following a
            Tenant Default;
          </li>
          <li>
            Any amounts due to be paid by the Tenant under a Lease that have
            been reduced or forgiven by the Landlord subsequent to the execution
            of the Lease, whether such reduction or forgiven amounts have been
            or were set forth by the Landlord voluntarily or mandated by an
            applicable governmental or court authority;
          </li>
          <li>
            Any sublet not approved by the Landlord at the inception of the
            Lease;
          </li>
          <li>
            The vacatur of a Unit by a Tenant due to the Tenant&#39;s military
            deployment or any other reason speciﬁed in the Lease for which the
            Landlord is required to release the Tenant from liability;
          </li>
          <li>
            Any dispute between the Landlord and the Tenant unrelated to a
            failure by the Tenant to pay monthly Rent in accordance with the
            Lease; or
          </li>
          <li>
            Any material breach of the Lease by the Tenant in a manner other
            than the failure to pay Rent or the failure to pay any other related
            fee required under the Lease.
          </li>
          <li>Theft of Landlord personal property;</li>
          <li>Damage to or theft of Tenant property;</li>
          <li>
            Vandalism and malicious mischief; except for Damages resulting from
            the Tenant;
          </li>
          <li>Weather related Damages in part or in whole to the Unit;</li>
          <li>
            Weather
            <ol type="a">
              <li>
                Flood, surface water, waves (including tidal waves, tsunami, and
                seiche), tides, tidal water, overﬂow of any body of water, or
                spray or surge from any of these, all whether driven by wind or
                not;
              </li>
              <li>Mudslide or mudﬂow;</li>
              <li>
                Water or sewage that backs up or overﬂows from a sewer, drain or
                sump;
              </li>
              <li>
                Water or sewage under the ground surface pressing on, or ﬂowing
                or seeping through foundations, walls, ﬂoors or paved surfaces;
                doors, windows or other openings;
              </li>
            </ol>
          </li>
          <li>
            Damage to other structures rented or held for rental to any person
            not a Tenant on the Lease, unless used solely as a private garage;
          </li>
          <li>
            Loss of use, including fair rental value, civil authority and
            additional living expenses for the Tenant, except for a Loss solely
            related to a skip/abandonment for missed rent;
          </li>
          <li>Additional living expenses for the Landlord and Tenant;</li>
          <li>Mold;</li>
          <li>Fire;</li>
          <li>
            Any personal property or furnishings provided or otherwise owned by
            Landlord that the Tenant will be using other than standard kitchen
            appliances, washing machines, dryers, and any permanent ﬁxtures
            including, but not limited to thermostats, smart devices, and any
            equipment related to utilities; or
          </li>
          <li>
            Short Term Rentals, Co-living Communities, or Temporary Housing
            <ol type="a">
              <li>
                Any lease for the short-term rental, as deﬁned by applicable
                law, or home sharing of the Landlord&#39;s Unit;
              </li>
              <li>
                Any lease for Landlord&#39;s Unit in a co-living and
                co-habitation community; or
                <ol type="1">
                  <li>
                    Approved student housing communities do not apply to this
                    exclusion.
                  </li>
                </ol>
              </li>
              <li>
                Any lease that provides temporary housing in the event the Unit
                is not available for the Tenant to take possession of on the
                date stated in the Lease.
              </li>
            </ol>
          </li>
          <li>
            any concessions or waived Security Deposit, any abatements, waivers,
            releases, Rent Credits, free rent, rent concession, or any other
            deductions of rent payable to a lower amount than stated in the
            lease, any punitive charges and fees in connection with lease
            termination, lease break, abandonment, or surrender that are not
            concurrent with a Lease Termination Agreement between Landlord and
            Tenant;
          </li>
          <li>
            any abatements, waivers, releases, Rent Credits, free rent, rent
            concession, or any other deductions of rent payable to a lower
            amount than stated in the lease;
          </li>
        </ol>
      </li>
    </ol>
  </>
);

const ExclusionsPage: FunctionComponent = observer(function ExclusionsPage() {
  return (
    <>
      <Header />
      <Container maxW="container.xl">
        <VStack justifyContent="cetner" paddingX="10px">
          <Container maxW="container.lg">
            <div className="markdown-body notranslate">{ExclusionsHtml}</div>
          </Container>
        </VStack>
      </Container>
      <Footer />
    </>
  );
});

export default ExclusionsPage;
